import { h, resolveComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import DefaultLayout from '@/layouts/DefaultLayout';

import { hasPermission } from '../utils/authUtils';
import { userRoles } from '@/constants/user-roles';

// Default Components:
// const Shipments = () => import('@/views/Shipments.vue');
// const LiveShipments = () => import('@/views/LiveShipments.vue');

const DashboardKlabin = () => import('@/views/DashboardKlabin.vue');
const Users = () => import('@/views/Users.vue');
const Tags = () => import('@/views/Tags.vue');
const Vehicles = () => import('@/views/Vehicles.vue');
const ShippingCompany = () => import('@/views/ShippingCompany.vue');
const Readers = () => import('@/views/Readers.vue');
const Routes = () => import('@/views/Routes.vue');
const Monitoring = () => import('@/views/Monitoring.vue');
const MonitoringByAntenna = () => import('@/views/MonitoringByAntenna.vue');
const Logs = () => import('@/views/Logs.vue');
const Questions = () => import('@/views/Questions.vue');
const Configurations = () => import('@/views/Configurations.vue');
const Antennas = () => import('@/views/Antennas.vue');
const Products = () => import('@/views/Products.vue');
const Checkpoints = () => import('@/views/Checkpoints.vue');
const Questionnaries = () => import('@/views/Questionnaries.vue');
const Reports = () => import('@/views/Reports.vue');
const Roles = () => import('@/views/Roles.vue');
const DataExports = () => import('@/views/DataExports.vue');
const Enterprises = () => import('@/views/Enterprises.vue');
const LiveVisits = () => import('@/views/LiveVisits.vue');
const Demo = () => import('@/views/Demo.vue');
const DashDemo = () => import('@/views/DashDemo.vue');

// Public components:
const Login = () => import('@/views/pages/Login');
const Register = () => import('@/views/pages/Register');
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const FinishRegistration = () => import('@/views/pages/FinishRegistration.vue');
const ForgotPassword = () => import('@/views/pages/ForgotPassword.vue');
const RecoverPassword = () => import('@/views/pages/RecoverPassword.vue');

function checkPermission(action, resource, roles = []) {
  return (to, from, next) => {
    if (hasPermission(action, resource, roles)) {
      next();
    } else {
      next('/500');
    }
  };
}

const routes = [
  // Default layout items:
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/login',
    children: [
      {
        path: '/dashboard',
        name: 'DashboardKlabin',
        component: DashboardKlabin,
        beforeEnter: checkPermission('access', 'dashboard', [
          userRoles.ADMIN,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.MANAGER,
          userRoles.PORTARIA,
          userRoles.KLABIN_MANAGER,
        ]),
      },
      {
        path: '/tempo-real',
        name: 'LiveVisits',
        component: LiveVisits,
        beforeEnter: checkPermission('access', 'live_visits', [
          userRoles.ADMIN,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.MANAGER,
          userRoles.FINANCEIRO,
          userRoles.KLABIN_MANAGER,
          userRoles.VISUALIZATION,
        ]),
      },
      // {
      //   path: '/carregamentos',
      //   name: 'Shipments',
      //   component: Shipments,
      //   beforeEnter: checkPermission('access', 'shipment', [
      //     userRoles.ADMIN,
      //     userRoles.ENTERPRISE_ADMIN,
      //     userRoles.MANAGER,
      //     userRoles.FINANCEIRO,
      //     userRoles.PORTARIA,
      //   ]),
      // },
      {
        path: '/veiculos',
        name: 'Vehicles',
        component: Vehicles,
        beforeEnter: checkPermission('access', 'vehicle', [
          userRoles.ADMIN,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.MANAGER,
          userRoles.PORTARIA,
        ]),
      },
      {
        path: '/transportadora',
        name: 'ShippingCompany',
        component: ShippingCompany,
        beforeEnter: checkPermission('access', 'shipping_company', [
          userRoles.ADMIN,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.MANAGER,
          userRoles.FINANCEIRO,
        ]),
      },
      {
        path: '/relatorios',
        name: 'Reports',
        component: Reports,
        beforeEnter: checkPermission('access', 'report', [
          userRoles.ADMIN,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.MANAGER,
          userRoles.KLABIN_MANAGER,
        ]),
      },
      {
        path: '/exportacao_de_dados',
        name: 'DataExports',
        component: DataExports,
        beforeEnter: checkPermission('access', 'report', [
          userRoles.ADMIN,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.MANAGER,
        ]),
      },
      {
        path: '/empresas',
        name: 'Enterprises',
        component: Enterprises,
        beforeEnter: checkPermission('access', 'enterprise', [userRoles.ADMIN]),
      },
      {
        path: '/sistema',
        name: 'System Configuration',
        component: {
          render() {
            return h(resolveComponent('router-view'));
          },
        },
        redirect: '/sistema/usuarios',
        children: [
          {
            path: '/sistema/cargos',
            name: 'Roles',
            component: Roles,
            beforeEnter: checkPermission('access', 'role', [userRoles.ADMIN]),
          },
          {
            path: '/sistema/usuarios',
            name: 'Users',
            component: Users,
            beforeEnter: checkPermission('access', 'user', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
              userRoles.MANAGER,
            ]),
          },
          {
            path: '/sistema/tags',
            name: 'Tags',
            component: Tags,
            beforeEnter: checkPermission('access', 'tag', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
              userRoles.MANAGER,
              userRoles.PORTARIA,
            ]),
          },

          {
            path: '/sistema/rotas',
            name: 'Routes',
            component: Routes,
            beforeEnter: checkPermission('access', 'route', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
              userRoles.MANAGER,
            ]),
          },

          {
            path: '/sistema/questoes',
            name: 'Questions',
            component: Questions,
            beforeEnter: checkPermission('access', 'question', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
              userRoles.MANAGER,
            ]),
          },
          {
            path: '/sistema/configuracoes',
            name: 'Configurations',
            component: Configurations,
            beforeEnter: checkPermission('access', 'configuration', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
            ]),
          },
          {
            path: '/sistema/antenas',
            name: 'Antennas',
            component: Antennas,
            beforeEnter: checkPermission('access', 'antenna', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
            ]),
          },
          {
            path: '/sistema/produtos',
            name: 'Products',
            component: Products,
            beforeEnter: checkPermission('access', 'product', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
            ]),
          },
          {
            path: '/sistema/estacoes',
            name: 'Checkpoints',
            component: Checkpoints,
            beforeEnter: checkPermission('access', 'checkpoint', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
            ]),
          },

          {
            path: '/sistema/questionarios',
            name: 'Questionnaries',
            component: Questionnaries,
            beforeEnter: checkPermission('access', 'questionnaire', [
              userRoles.ADMIN,
              userRoles.ENTERPRISE_ADMIN,
              userRoles.MANAGER,
            ]),
          },
          {
            path: '/sistema/monitoramento',
            name: 'Monitoring',
            component: Monitoring,
            beforeEnter: checkPermission('access', 'monitoring', [
              userRoles.ADMIN,
            ]),
          },
          {
            path: '/sistema/historico/antena',
            name: 'MonitoringByAntenna',
            component: MonitoringByAntenna,
            beforeEnter: checkPermission('history', 'monitoring', [
              userRoles.ADMIN,
            ]),
          },
          {
            path: '/sistema/logs',
            name: 'Logs',
            component: Logs,
            beforeEnter: checkPermission('logs', 'monitoring', [
              userRoles.ADMIN,
            ]),
          },
          {
            path: '/sistema/leitura',
            name: 'Readers',
            component: Readers,
            beforeEnter: checkPermission('access', 'reader', [userRoles.ADMIN]),
          },
        ],
      },
    ],
  },
  // Public layout items:
  {
    path: '/',
    redirect: '/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
      {
        path: '/demo',
        name: 'Demo',
        component: Demo,
      },
      {
        path: '/dash_demo',
        name: 'DashDemo',
        component: DashDemo,
      },
      {
        path: '/register',
        name: 'Register',
        component: Register,
      },
      {
        path: '/completar-cadastro',
        name: 'FinishRegistration',
        component: FinishRegistration,
      },

      {
        path: '/esqueci-senha',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },

      {
        path: '/recuperar-senha',
        name: 'RecoverPassword',
        component: RecoverPassword,
      },
      {
        path: '/404',
        name: 'Page404',
        component: Page404,
      },
      {
        path: '/500',
        name: 'Page500',
        component: Page500,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;

// Examples::
// {
//   path: '/charts',
//   name: 'Charts',
//   component: () => import('@/views/charts/Charts.vue'),
// },
// {
//   path: '/icons',
//   name: 'Icons',
//   component: {
//     render() {
//       return h(resolveComponent('router-view'));
//     },
//   },
//   redirect: '/icons/coreui-icons',
//   children: [
//     {
//       path: '/icons/coreui-icons',
//       name: 'CoreUI Icons',
//       component: () => import('@/views/icons/CoreUIIcons.vue'),
//     },
//     {
//       path: '/icons/brands',
//       name: 'Brands',
//       component: () => import('@/views/icons/Brands.vue'),
//     },
//     {
//       path: '/icons/flags',
//       name: 'Flags',
//       component: () => import('@/views/icons/Flags.vue'),
//     },
//   ],
// },
// {
//   path: '/notifications',
//   name: 'Notifications',
//   component: {
//     render() {
//       return h(resolveComponent('router-view'));
//     },
//   },
//   redirect: '/notifications/alerts',
//   children: [
//     {
//       path: '/notifications/alerts',
//       name: 'Alerts',
//       component: () => import('@/views/notifications/Alerts.vue'),
//     },
//     {
//       path: '/notifications/badges',
//       name: 'Badges',
//       component: () => import('@/views/notifications/Badges.vue'),
//     },
//     {
//       path: '/notifications/modals',
//       name: 'Modals',
//       component: () => import('@/views/notifications/Modals.vue'),
//     },
//   ],
// },
// {
//   path: '/widgets',
//   name: 'Widgets',
//   component: () => import('@/views/widgets/Widgets.vue'),
// },
